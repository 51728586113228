body {
  margin: auto;
  width: 50%;
  border: 0px solid black;
  padding: 10px;
  text-align: justify;
  font-family: "Open Sans";
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.container {
  margin: 50px 0px 20px 0px;
}

.header {
  font-size: 2.5em;
  text-transform: uppercase;
  font-weight: 800;
}

.section-container {
  padding-bottom: 15px;
}

.section-title {
  font-size: 1.5em;
  font-weight: 800;
  color: #007fab;
}

.section-subtitle {
  font-size: 1.2em;
  font-weight: 800;
  padding-bottom: 10px;
  font-family: "Montserrat";
}

.section-date {
  color: #595959;
}

.section-description {
  padding-top: 15px;
}

.section-additional-info-label {
  font-weight: 800;
  padding-top: 15px;
}
.section-additional-info {
  font-family: "Montserrat";
}

@media only screen and (max-width: 900px) {
  body {
    width: 95%;
  }
}

@media only screen and (max-width: 900px) {
  .row {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 800;
  }
  .section-title {
    font-size: 1.2em;
    font-weight: 800;
    color: #007fab;
  }

  .section-subtitle {
    font-size: 1em;
    font-weight: 800;
    padding-bottom: 10px;
    font-family: "Montserrat";
  }
  .section-date {
    color: #595959;
    font-size: 0.8em;
  }
}
