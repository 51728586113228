.name {
  font-size: 2em;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: 800;
}

@media only screen and (max-width: 900px) {
  .name {
    font-size: 1.6em;
  }
}
