.skills-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}

.skills-list {
  float: left;
  margin: 5px;
  padding: 10px;
  background-color: #007fab;
  color: white;
  border-radius: 15px;
}

.skills-list:hover {
  float: left;
  margin: 5px;
  padding: 10px;
  background-color: #005470;
  color: white;
  border-radius: 15px;
}
