.fa-location-dot,
.fa-envelope-open,
.fa-phone,
.fa-linkedin,
.fa-globe {
  color: #007fab;
}

.contacts-container {
  width: 40%;
  border: 0px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contacts-info {
  padding: 1px 0px 1px 20px;
  display: flex;
  justify-content: flex-end;
  font-size: 1em;
  padding: 3px 0px 3px 0px;
}

@media only screen and (max-width: 900px) {
  .contacts-container {
    width: 100%;
    display: block;
    margin-bottom: 50px;
  }

  .contacts-info {
    padding: 1px 0px 1px 20px;
    display: flex;
    justify-content: flex-start;
    font-size: 1em;
    padding: 3px 0px 3px 0px;
  }
}
